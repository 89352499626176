.greeting_header {
    font-size: 66px;
    color: var(--f7-bars-text-color);
    line-height: 76px;
    font-family: 'Montserrat', sans-serif;
    text-shadow: 0px 0px 100px var(--f7-bars-bg-color);
}

.greeting_body {
    font-size: 22px;
    color: var(--f7-bars-text-color);
    line-height: 40px;
    max-width: 500px;
    margin-left: 0;
    margin-top: 20px;
    text-shadow: 0 0 15px var(--f7-bars-bg-color);
}

.greeting_block {
    top: 0;
    margin: 0;
    width: 100%;
    min-height: 708px;
    background-color: var(--f7-bars-bg-color);
}

.greeting_block .border-divider {
    width: 100%;
    height: 2px;
    background-color: #121113;
    position: absolute;
    top: 708px

}

.greeting_block .greeting-logo {
    position: absolute;
    top: 18px;
    left: 40px;
    width: 71px;
    height: 61px;
    background: url(../../../../img/logo/logo_white.svg) no-repeat center;
    background-size: cover;
}

.greeting_block .left-menu {
    position: absolute;
    top: 20px;
    right: 40px;
    height: 60px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.greeting_block .left-menu .c-link {
    padding: 0 15px;
    font-size: 18px;
    color: var(--f7-bars-text-color);
}

.greeting_block .left-menu .icon-only {
    margin: 0;
}

.greeting_block .left-menu .button {
    margin-left: 20px;
}

.greeting_block .greeting_text_block {
    position: absolute;
    width: 100%;
    top: 170px;
    z-index: 1;
}

.greeting_block .greeting_text_block .greeting_text_inner {
    width: 80%;
    max-width: 880px;
    margin-left: auto;
    margin-right: auto;
}

.greeting_block .greeting-avatar{
    position: relative;
    width: 100%;
    height: 480px;
    top: 230px;
    width: 100%;
    z-index: 0;
}

.greeting_block .greeting-avatar .greeting-avatar-inner{
    height: 480px;
    width: 100%;
    max-width: 1320px;
    margin: auto;
    background: url(../../../../img/cogitron/cogitron-bg.png) no-repeat;
    background-size: 700px 100%;
    background-position: calc(50% + 220px) 100%;
}

.greeting_block .greeting_btn{
    margin-top: 30px;
    background-color:#9b59b6;
    text-transform: none;
    width: 220px;
    font-size: 18px;
    box-shadow: 0 0 20px var(--f7-bars-bg-color);
}

.greeting-particles {
    margin-top:-20px;
    height: 246px;
}

@media only screen and (max-width: 1200px) and (min-width: 980px){
    .greeting_block .greeting-avatar .greeting-avatar-inner{
        background-position: calc((50% * .8) + 266px) 100%;
    }
}

@media only screen and (max-width: 979px) and (min-width: 768px){
    .greeting_block .greeting-avatar .greeting-avatar-inner{
        background-position: calc((50% * .4) + 336px) 100%;
    }
}

@media only screen and (max-width: 914px) and (min-width: 768px){

    .greeting_block .left-menu .c-link {
        padding: 0 8px;
        font-size: 17.5px;
    }

    .greeting_block .greeting-logo {
        left: 30px;
    }

    .greeting_block .left-menu {
        right: 30px;
    }
    

}

@media only screen and (max-width: 767px) {
    
    .greeting_block .greeting-avatar{
        top: 420px;
    }

    .greeting_block .greeting-avatar .greeting-avatar-inner{
        /*display: none;*/
        height: 330px;
        background-size: 500px 100%;
        background-position: calc(50% + 20px) 100%;
    }
    
    
    .greeting_block {
        min-height: 748px;
    }

    .greeting-particles {
        margin-top:-136px;
        height: 394px;
    }
    
    .greeting_block .border-divider {
        width: 100%;
        height: 2px;
        background-color: #1F1B62;
        position: absolute;
        top: 748px
    
    }

    .greeting_block .greeting-logo {
        left: 20px;
        width: 62px;
        height: 53px;
    }

    .greeting_block .left-menu {
        right: 12px;
        top:18px;
    }

    .greeting_header {
        font-size: 40px;
        line-height: 46px;
    }
    
    .greeting_body {
        font-size: 18px;
        line-height: 24px;
    }

    .greeting_block .greeting_text_block {
        position: absolute;
        width: 100%;
        top: 150px;
    }
    
    .greeting_block .greeting_text_block .greeting_text_inner {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    

  }

  @media only screen and (max-width: 340px){


    .greeting_header {
        font-size: 30px;
    }

}

#tsparticles {
    z-index: 100;
}