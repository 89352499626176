@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
  .pageBlockDiv {
    display: inline-block;
    width: 100%;
  }

  .pageBlockDiv .center {
    width:100%;
    max-width:660px;
    margin-left:auto;
    margin-right:auto;
    margin-top: 0;
  }
.greeting_header {
    font-size: 66px;
    color: var(--f7-bars-text-color);
    line-height: 76px;
    font-family: 'Montserrat', sans-serif;
    text-shadow: 0px 0px 100px var(--f7-bars-bg-color);
}

.greeting_body {
    font-size: 22px;
    color: var(--f7-bars-text-color);
    line-height: 40px;
    max-width: 500px;
    margin-left: 0;
    margin-top: 20px;
    text-shadow: 0 0 15px var(--f7-bars-bg-color);
}

.greeting_block {
    top: 0;
    margin: 0;
    width: 100%;
    min-height: 708px;
    background-color: var(--f7-bars-bg-color);
}

.greeting_block .border-divider {
    width: 100%;
    height: 2px;
    background-color: #121113;
    position: absolute;
    top: 708px

}

.greeting_block .greeting-logo {
    position: absolute;
    top: 18px;
    left: 40px;
    width: 71px;
    height: 61px;
    background: url(/static/static/media/logo_white.8d6f8fb9.svg) no-repeat center;
    background-size: cover;
}

.greeting_block .left-menu {
    position: absolute;
    top: 20px;
    right: 40px;
    height: 60px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.greeting_block .left-menu .c-link {
    padding: 0 15px;
    font-size: 18px;
    color: var(--f7-bars-text-color);
}

.greeting_block .left-menu .icon-only {
    margin: 0;
}

.greeting_block .left-menu .button {
    margin-left: 20px;
}

.greeting_block .greeting_text_block {
    position: absolute;
    width: 100%;
    top: 170px;
    z-index: 1;
}

.greeting_block .greeting_text_block .greeting_text_inner {
    width: 80%;
    max-width: 880px;
    margin-left: auto;
    margin-right: auto;
}

.greeting_block .greeting-avatar{
    position: relative;
    width: 100%;
    height: 480px;
    top: 230px;
    width: 100%;
    z-index: 0;
}

.greeting_block .greeting-avatar .greeting-avatar-inner{
    height: 480px;
    width: 100%;
    max-width: 1320px;
    margin: auto;
    background: url(/static/static/media/cogitron-bg.1ece0bb8.png) no-repeat;
    background-size: 700px 100%;
    background-position: calc(50% + 220px) 100%;
}

.greeting_block .greeting_btn{
    margin-top: 30px;
    background-color:#9b59b6;
    text-transform: none;
    width: 220px;
    font-size: 18px;
    -webkit-box-shadow: 0 0 20px var(--f7-bars-bg-color);
            box-shadow: 0 0 20px var(--f7-bars-bg-color);
}

.greeting-particles {
    margin-top:-20px;
    height: 246px;
}

@media only screen and (max-width: 1200px) and (min-width: 980px){
    .greeting_block .greeting-avatar .greeting-avatar-inner{
        background-position: calc((50% * .8) + 266px) 100%;
    }
}

@media only screen and (max-width: 979px) and (min-width: 768px){
    .greeting_block .greeting-avatar .greeting-avatar-inner{
        background-position: calc((50% * .4) + 336px) 100%;
    }
}

@media only screen and (max-width: 914px) and (min-width: 768px){

    .greeting_block .left-menu .c-link {
        padding: 0 8px;
        font-size: 17.5px;
    }

    .greeting_block .greeting-logo {
        left: 30px;
    }

    .greeting_block .left-menu {
        right: 30px;
    }
    

}

@media only screen and (max-width: 767px) {
    
    .greeting_block .greeting-avatar{
        top: 420px;
    }

    .greeting_block .greeting-avatar .greeting-avatar-inner{
        /*display: none;*/
        height: 330px;
        background-size: 500px 100%;
        background-position: calc(50% + 20px) 100%;
    }
    
    
    .greeting_block {
        min-height: 748px;
    }

    .greeting-particles {
        margin-top:-136px;
        height: 394px;
    }
    
    .greeting_block .border-divider {
        width: 100%;
        height: 2px;
        background-color: #1F1B62;
        position: absolute;
        top: 748px
    
    }

    .greeting_block .greeting-logo {
        left: 20px;
        width: 62px;
        height: 53px;
    }

    .greeting_block .left-menu {
        right: 12px;
        top:18px;
    }

    .greeting_header {
        font-size: 40px;
        line-height: 46px;
    }
    
    .greeting_body {
        font-size: 18px;
        line-height: 24px;
    }

    .greeting_block .greeting_text_block {
        position: absolute;
        width: 100%;
        top: 150px;
    }
    
    .greeting_block .greeting_text_block .greeting_text_inner {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    

  }

  @media only screen and (max-width: 340px){


    .greeting_header {
        font-size: 30px;
    }

}

#tsparticles {
    z-index: 100;
}
.project-card {
  background-color: #ffffff;
}
.project-card .row-card {
  display: -ms-flexbox;
  display: flex;
}
.project-card .col-card {
  -ms-flex: 1 1;
      flex: 1 1;
  padding: 1em;
}
.project-card .project-card-bg {
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--f7-theme-color);
  width: 100%;
}

.project-card_1 .row-card {
  display: -ms-flexbox;
  display: flex;
}
.project-card_1 .col-card {
  -ms-flex: 1 1;
      flex: 1 1;
  padding: 1em;
}
.project-card_1 .project-card-bg {
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--f7-theme-color);
  width: 100%;
}

/* Material Icons Font (for MD theme) */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/static/static/media/MaterialIcons-Regular.e79bfd88.eot); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(/static/static/media/MaterialIcons-Regular.570eb838.woff2) format('woff2'),
       url(/static/static/media/MaterialIcons-Regular.012cf6a1.woff) format('woff'),
       url(/static/static/media/MaterialIcons-Regular.a37b0c01.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
}

/* Framework7 Icons Font (for iOS theme) */
@font-face {
  font-family: 'Framework7 Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/static/static/media/Framework7Icons-Regular.f30e0292.eot);
  src: url(/static/static/media/Framework7Icons-Regular.9393ad14.woff2) format("woff2"),
       url(/static/static/media/Framework7Icons-Regular.8f897db6.woff) format("woff"),
       url(/static/static/media/Framework7Icons-Regular.1b6b2c3e.ttf) format("truetype");
}

.f7-icons {
  font-family: 'Framework7 Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 25px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: "liga";
  font-feature-settings: "liga";
  text-align: center;
}

:root {
    --f7-theme-color: #13395B;
    --f7-theme-color-rgb: 0, 122, 255;
    --f7-theme-color-shade: #1E2439;
    --f7-theme-color-tint: #364369;
    --f7-bars-bg-color:  #13395B;
    --f7-bars-link-color: #ffffff;
    --f7-bars-text-color: #ffffff;
    --f7-safe-area-left: 0px;
    --f7-safe-area-right: 0px;
    --f7-safe-area-top: 0px;
    --f7-safe-area-bottom: 0px;
    --f7-safe-area-outer-left: 0px;
    --f7-safe-area-outer-right: 0px;
    --f7-device-pixel-ratio: 1;
    --f7-block-title-white-space: normal;
    --f7-page-bg-color:#EFF4F9;
    --f7-list-item-text-max-lines: 12;
    --f7-popup-tablet-border-radius: 20px;
  }

  @media only screen and (min-width: 768px) {
    .hideFullScreen{
      display: none !important;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .hideMobileScreen {
      display: none !important;
    }
  }

  .c-text-link {
    text-decoration: underline;
  }

  h2 {
    font-size: 34px;
    line-height: 40px;
    font-weight: 500;
    margin-top: var(--f7-block-margin-vertical);
    margin-bottom: var(--f7-block-title-margin-bottom);
    margin-left: calc(var(--f7-block-padding-horizontal) + 0px);
    margin-left: calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-left));
    margin-right: calc(var(--f7-block-padding-horizontal) + 0px);
    margin-right: calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-right));
  }
  h5 {
  font-size: 25px;
  line-height: 30px;
  font-weight: 500;
}

@media only screen and (max-width: 640px) and (min-width: 461px) {
  h2 {
    font-size: 30px;
    line-height: 34px;
  }
  h5 {
  font-size: 22px;
  line-height: 26px;
  }

}

@media only screen and (max-width: 460px) {
  h2 {
    font-size: 28px;
    line-height: 32px;
  }
  h5 {
  font-size: 20px;
  line-height: 24px;
  }

}

.block-title {
  font-family: 'Montserrat', sans-serif;
}

.block {
  font-family: 'Roboto', sans-serif;
}
