.project-card {
  background-color: #ffffff;
}
.project-card .row-card {
  display: flex;
}
.project-card .col-card {
  flex: 1;
  padding: 1em;
}
.project-card .project-card-bg {
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--f7-theme-color);
  width: 100%;
}

.project-card_1 .row-card {
  display: flex;
}
.project-card_1 .col-card {
  flex: 1;
  padding: 1em;
}
.project-card_1 .project-card-bg {
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--f7-theme-color);
  width: 100%;
}
